import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";

import WalletButton from "./WalletButton";
import useImagePreloader from "../hooks/ImagePreloader";
import { Link } from "react-router-dom";
import { Discord, TwitterX } from "react-bootstrap-icons";

const icon = require('../assets/images/logo64.png');
const logo = require('../assets/images/fancy-logo.png');

const images = [
    icon,
    logo
]

export const Header = () => {
    useImagePreloader(images);

    return (<header>
        <Navbar variant="dark" bg="dark">
            <Container fluid className="d-none d-md-flex">
                <Navbar.Brand as={Link} to={"/"}>
                    <img src={icon} height={32} alt="THC Labz" className="d-inline-block align-bottom" /> The Growerz Hub
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="text-light">
                    <Nav className="me-auto">
                        <Nav.Link href={"https://x.com/thc_labz"} title="X (twitter)" target="_blank" rel="noreferrer">
                            <TwitterX size={28} />
                        </Nav.Link>
                        <Nav.Link href={"https://discord.gg/yHQCpf3fpp"} title="Discord" target="_blank" rel="noreferrer">
                            <Discord size={28} />
                        </Nav.Link>
                    </Nav>
                    <div className="d-flex">
                        <WalletButton />
                    </div>
                </Navbar.Collapse>
            </Container>
            <Container fluid className="d-block d-md-none">
                <Row className="justify-content-center my-3">
                    <Col className="text-center">
                        <Navbar.Brand as={Link} to={"/"}>
                            <img src={icon} height={32} alt="THC Labz" className="d-inline-block align-bottom" /> The Growerz Hub
                        </Navbar.Brand>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    </header>);
}

export const Version = () => {
    return (
        <span className="growerz-version">

        </span>
    )
}

export const MobileWalletButton = () => {
    return <Navbar fixed="bottom" className="pb-0">
        <Container fluid className="d-block d-md-none sticky-footer bg-dark">
            <Row className="my-3">
                <Col>
                    <div className="text-light">
                        <WalletButton />
                    </div>
                </Col>
            </Row>
        </Container>
    </Navbar>
}

export const Footer = () => {
    return (<footer>
        <Container className="d-block py-3 pb-5 pb-md-0 mb-5">
            <hr className="text-white" />
            <Row>
                <Col className="text-center">
                    <a href="https://thc-labz.xyz" title="THC Labz" target="_blank" rel="noreferrer">
                        <img src={logo} alt="THC Labz" height={128} />
                    </a>
                    <br />
                </Col>
            </Row>
        </Container>
        <div className="fs-7" style={{ position: "fixed", bottom: 5, left: 5 }}>
            Growerz Hub v{process.env.REACT_APP_VERSION}
        </div>
    </footer>);
}
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApi } from '../contexts/ApiContext';
import { useSolanaWallet } from './useSolanaWallet';

// Define the context
interface ExchangeContextProps {
    sol: number,
    thc: number,
    rate: number,
    updateSol: (event: any) => void,
    updateThc: (event: any) => void,
    resetExchange: () => void,
}

const ExchangeContext = createContext<ExchangeContextProps>({
    sol: 0.0000242069,
    thc: 1,
    rate: 0.0000242069,
    updateSol: () => { },
    updateThc: () => { },
    resetExchange: () => { },
});

// Define the custom hook
export const useExchange = () => {
    const context = useContext(ExchangeContext);
    if (!context) {
        throw new Error('useExchange must be used within a ExchangeProvider');
    }
    return context;
};

// Define the provider component
export const ExchangeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { get } = useApi();
    const { balance } = useSolanaWallet();

    const [sol, setSol] = useState<number>(0);
    const [thc, setThc] = useState<number>(0);
    const [rate, setRate] = useState<number>(0);

    const resetExchange = useCallback(() => {
        setSol(rate);
        setThc(1);
    }, [rate]);

    const updateSol = useCallback((event: any) => {
        let value = Number(event.currentTarget.value);
        if (value >= balance) value = balance;
        if (value <= 0) value = 0;
        let newThc = value / rate;
        value = Number(value.toFixed(10));
        setSol(value);
        setThc(Number(newThc.toFixed(2)));
    }, [balance, rate]);

    const updateThc = useCallback((event: any) => {
        let value = Number(event.currentTarget.value);
        let newSol = value * rate;
        if (newSol >= balance) {
            newSol = balance;
            value = balance * rate;
        }
        if (value <= 0) value = 0;
        value = Number(value.toFixed(2));
        setSol(newSol);
        setThc(value);
    }, [balance, rate]);

    useEffect(() => {
        get('/dispensary/exchange/rate').then((response) => {
            if (!response.success) {
                setRate(0.0000242069);
            } else {
                setRate(response.data);
            }
            resetExchange();
        });
    }, [get, resetExchange]);

    const value = useMemo(() => ({
        sol,
        thc,
        rate,
        updateSol,
        updateThc,
        resetExchange,
    }), [sol, thc, rate, updateSol, updateThc, resetExchange]);

    return (
        <ExchangeContext.Provider value={value}>
            {children}
        </ExchangeContext.Provider>
    );
}

export default useExchange;

import { useState } from "react";
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { VersionedTransaction } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { GrowhouseNewUpgradeInfo, PaymentMethod, roundDecimal } from "@growerz/shared";

import { useApi } from "../../../contexts/ApiContext";
import { useNotification } from "../../../contexts/NotificationContext";
import { LoadingBackdrop } from "../../common/Loading";
import useGrowhouse from "../../../hooks/useGrowhouse";
import { createPlayerTransaction } from "../../../helpers/SolanaHelper";

const GrowhouseUpgradeModal = ({
    paymentMethod,
    upgradeable
}: {
    paymentMethod: PaymentMethod,
    upgradeable: boolean
}) => {
    const { post } = useApi();
    const { addErrorNotification } = useNotification();
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const { growhouse, refreshData } = useGrowhouse();
    const [upgrading, setUpgrading] = useState(false);
    const [showing, setShowing] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const [newUpgradeInfo, setNewUpgradeInfo] = useState<GrowhouseNewUpgradeInfo>();

    function show() {
        setShowing(true);
    }

    function hide() {
        setNewUpgradeInfo(undefined);
        setShowing(false);
        refreshData();
    }

    async function handleUpgrade() {
        if (!growhouse || growhouse.max_level || !sendTransaction || upgrading) return;

        if (!upgradeable) {
            addErrorNotification("Upgrade Failed", "Qualifications are not met to upgrade this Growhouse!");
            return;
        }

        if (publicKey) {
            setUpgrading(true);
            setLoadingMessage("Upgrading Growhouse...");

            let timer: NodeJS.Timeout | undefined;
            const startTimer = () => {
                timer = setTimeout(() => {
                    setLoadingMessage("Please be patient...");
                }, 5000);
            };

            const clearTimer = () => {
                if (timer) {
                    clearTimeout(timer);
                    timer = undefined;
                }
            };

            if (growhouse.level === 10) {
                try {
                    const price = paymentMethod === PaymentMethod.THC ? growhouse.upgrade_status!.thc_required : growhouse.upgrade_status!.sol_required;
                    const transaction = await createPlayerTransaction(connection, publicKey, price, paymentMethod);
                    const signature = await sendTransaction(transaction as VersionedTransaction, connection);

                    startTimer();

                    const response = await post(`/growhouse/${growhouse.id}/maxupgrade`, {
                        transaction: signature
                    });

                    if (!response.success) throw new Error(response.message);

                    setNewUpgradeInfo(response.data);
                    show();
                } catch (error: any) {
                    if (error.name === "WalletSignMessageError")
                        addErrorNotification("Action failed", "User rejected transaction!");
                    else
                        addErrorNotification("Action failed", "Failed to upgrade Growhouse, contact support!");

                    console.error("Failed to upgrade Growhouse: " + error.message);
                } finally {
                    setUpgrading(false);
                    setLoadingMessage(undefined);
                    clearTimer();
                }
            } else {
                try {
                    const price = paymentMethod === PaymentMethod.THC ? growhouse.upgrade_status!.thc_required : growhouse.upgrade_status!.sol_required;
                    const transaction = await createPlayerTransaction(connection, publicKey, price, paymentMethod);
                    const signature = await sendTransaction(transaction as VersionedTransaction, connection);

                    startTimer();

                    const response = await post(`/growhouse/${growhouse.id}/upgrade`, {
                        transaction: signature
                    });

                    if (!response.success) throw new Error(response.message);

                    setNewUpgradeInfo(response.data);
                    show();
                } catch (error: any) {
                    if (error.name === "WalletSignMessageError")
                        addErrorNotification("Action failed", "User rejected transaction!");
                    else
                        addErrorNotification("Action failed", "Failed to upgrade Growhouse, contact support!");

                    console.error("Failed to upgrade Growhouse: " + error.message);
                } finally {
                    setUpgrading(false);
                    setLoadingMessage(undefined);
                    clearTimer();
                }
            }
        }
    }

    return <>
        <LoadingBackdrop showing={upgrading} message={loadingMessage} />
        {newUpgradeInfo && growhouse && growhouse.base && (
            <Modal variant="dark" show={showing} onHide={hide} size="lg" className="modal-celebrate text-white" centered>
                <Modal.Header className="text-center">
                    <h1 className="mx-auto ff-thumbs pt-1 text-white">Growhouse Upgraded</h1>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="text-center mt-3">Your growhouse is now level <span className="text-success">{newUpgradeInfo.newLevel}</span>!</h3>
                    <Row className="justify-content-center g-0 fs-6 fs-md-5">
                        <Col xs={6}>
                            <ul className="mt-3 text-end no-liststyle">
                                {newUpgradeInfo.newGrowers && <li>Max&nbsp;Growers:</li>}
                                {(newUpgradeInfo.maxPlantsDifference > 0) && <li>Max&nbsp;Plants:</li>}
                                {(newUpgradeInfo.maxSeeds > growhouse.base.maxSeeds) && <li>Max&nbsp;Seeds:</li>}
                                {(newUpgradeInfo.avgWateringTime < growhouse.base.wateringTime) && <li>Watering&nbsp;Time:</li>}
                                {(newUpgradeInfo.avgHarvestBonus > growhouse.base.harvestBonus) && <li>Harvest&nbsp;Bonus:</li>}
                            </ul>
                        </Col>
                        <Col xs={6}>
                            <ul className="mt-3 text-start no-liststyle">
                                {newUpgradeInfo.newGrowers && (<li>{newUpgradeInfo.maxGrowers} <small className="text-success">(+1)</small></li>)}
                                {(newUpgradeInfo.maxPlantsDifference > 0) && <li>{newUpgradeInfo.maxPlants} <small className="text-success">(+{newUpgradeInfo.maxPlantsDifference})</small></li>}
                                {(newUpgradeInfo.maxSeeds > growhouse.base.maxSeeds) && <li>{newUpgradeInfo.maxSeeds} <small className="text-success">(+{newUpgradeInfo.maxSeedsDifference})</small></li>}
                                {(newUpgradeInfo.avgWateringTime < growhouse.base.wateringTime) && <li>{roundDecimal(newUpgradeInfo.avgWateringTime, 2)}hrs <small className="text-success">(-{roundDecimal(newUpgradeInfo.waterTimeDifference, 2)}hrs)</small></li>}
                                {(newUpgradeInfo.avgHarvestBonus > growhouse.base.harvestBonus) && <li>{roundDecimal(newUpgradeInfo.avgHarvestBonus, 2)}x <small className="text-success">(+{roundDecimal(newUpgradeInfo.harvestBonusDifference, 2)}x)</small></li>}
                            </ul>
                        </Col>
                    </Row>
                    <div className="text-center my-1">New Planting Upgrade Requirements</div>
                    <Row className="justify-content-center g-0 fs-6 fs-md-5">
                        <Col xs={6}>
                            <ul className="mt-3 text-end no-liststyle">
                                {(newUpgradeInfo.nextLevelRequirements.harvests.common > 0) && <li>Common: </li>}
                                {(newUpgradeInfo.nextLevelRequirements.harvests.uncommon > 0) && <li>Uncommon: </li>}
                                {(newUpgradeInfo.nextLevelRequirements.harvests.rare > 0) && <li>Rare: </li>}
                                {(newUpgradeInfo.nextLevelRequirements.harvests.epic > 0) && <li>Epic: </li>}
                                {(newUpgradeInfo.nextLevelRequirements.harvests.mythic > 0) && <li>Mythic: </li>}
                            </ul>
                        </Col>
                        <Col xs={6}>
                            <ul className="mt-3 text-start no-liststyle">
                                {(newUpgradeInfo.nextLevelRequirements.harvests.common > 0) && <li>{newUpgradeInfo.nextLevelRequirements.harvests.common}</li>}
                                {(newUpgradeInfo.nextLevelRequirements.harvests.uncommon > 0) && <li>{newUpgradeInfo.nextLevelRequirements.harvests.uncommon}</li>}
                                {(newUpgradeInfo.nextLevelRequirements.harvests.rare > 0) && <li>{newUpgradeInfo.nextLevelRequirements.harvests.rare}</li>}
                                {(newUpgradeInfo.nextLevelRequirements.harvests.epic > 0) && <li>{newUpgradeInfo.nextLevelRequirements.harvests.epic}</li>}
                                {(newUpgradeInfo.nextLevelRequirements.harvests.mythic > 0) && <li>{newUpgradeInfo.nextLevelRequirements.harvests.mythic}</li>}
                            </ul>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="text-center">
                            <Button variant="thc" size={'lg'} className="text-white px-3" onClick={hide}>Continue</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )}

        {growhouse && growhouse.max_level && (<Button variant="thc" className='w-100' size="sm" disabled>
            Maximum Level
        </Button>)}

        {growhouse && !growhouse.max_level && !upgradeable && (
            <OverlayTrigger
                key={"disabled-tooltip"}
                placement={"bottom"}
                overlay={
                    <Tooltip id={`tooltip-disabled`}>
                        Complete all non-exempt requirements to upgrade
                    </Tooltip>
                }
            >
                <span className="d-inline-block w-100">
                    <Button variant="thc" className="w-100 text-white" size="sm" disabled style={{ pointerEvents: 'none' }}>
                        {growhouse.level < 10 && (
                            'Upgrade'
                        )}
                        {growhouse.level === 10 && (
                            'Fully Upgrade'
                        )}
                    </Button>
                </span>
            </OverlayTrigger>
        )}

        {growhouse && !growhouse.max_level && upgradeable && (
            <Button variant="thc" className="w-100 text-white" size="sm" disabled={upgrading} onClick={handleUpgrade}>
                {growhouse.level < 10 && (
                    upgrading ? 'Upgrading...' : 'Upgrade'
                )}
                {growhouse.level === 10 && (
                    upgrading ? 'Upgrading...' : 'Fully Upgrade'
                )}
            </Button>
        )}
    </>
}

export default GrowhouseUpgradeModal;
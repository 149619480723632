"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MicroService = void 0;
const enum_1 = require("./enum");
// Interface for a Microservice
class MicroService {
    constructor(name, shortName, intervalMs) {
        this.status = enum_1.ServiceStatus.STOPPED;
        this.intervalId = null;
        this.name = name !== null && name !== void 0 ? name : '';
        this.shortName = shortName !== null && shortName !== void 0 ? shortName : '';
        this.intervalMs = intervalMs !== null && intervalMs !== void 0 ? intervalMs : 0;
    }
    // Starts the service and runs the task on the specified interval
    start() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.intervalMs === 0)
                throw new Error('Service interval is required');
            return new Promise((resolve, reject) => {
                if (this.status === enum_1.ServiceStatus.IDLE || this.status === enum_1.ServiceStatus.RUNNING) {
                    reject(new Error('Service is already running'));
                }
                else {
                    this.status = enum_1.ServiceStatus.IDLE;
                    this.intervalId = setInterval(() => {
                        this.run();
                    }, this.intervalMs);
                    resolve(true);
                }
            });
        });
    }
    // Stops the service
    stop() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                if ((this.status === enum_1.ServiceStatus.STOPPED || this.status === enum_1.ServiceStatus.ERROR) && this.intervalId) {
                    reject(new Error('Service is not running'));
                }
                else if (!this.intervalId) {
                    reject(new Error('Interval is missing'));
                }
                else {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                    this.status = enum_1.ServiceStatus.STOPPED;
                    resolve(true);
                }
            });
        });
    }
    // Runs the service
    run() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                if (this.status === enum_1.ServiceStatus.IDLE && this.intervalId) {
                    this.status = enum_1.ServiceStatus.RUNNING;
                    this.task().then((result) => {
                        this.status = enum_1.ServiceStatus.IDLE;
                        resolve(result);
                    });
                }
                else {
                    reject(new Error('Service is not in an idle state to run'));
                }
            });
        });
    }
}
exports.MicroService = MicroService;
